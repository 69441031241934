import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../admin/shared/BasicModal';
import { closeSocialShareModal } from '../../../shared/ModalActions';
import ShareLinkForm from '../ShareLinkForm/ShareLinkForm';

const SocialShareModal = () => {
  const showSocialShareModal = useSelector(state => state.modals.showSocialShareModal);
  const dispatch = useDispatch();

  if (!showSocialShareModal) {
    return null;
  }
  return (
    <BasicModal
      closeModal={() => dispatch(closeSocialShareModal())}
      show={showSocialShareModal}
      size="sm"
      title="Share Link"
    >
      <ShareLinkForm closeModal={() => dispatch(closeSocialShareModal())} />
    </BasicModal>
  );
};

export default SocialShareModal;
