export default theme => ({
  textarea: {
    '& textarea': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    backgroundColor: '#CCC',
    marginBottom: theme.spacing(1),
  },
});
